<template>
  <svg
    class="h-6 w-6 fill-current"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9748 13.2928L7.94286 9.32587C7.72946 9.11715 7.44078 9 7.13988 9C6.83898 9 6.55031 9.11715 6.33691 9.32587C6.23015 9.43004 6.14542 9.55398 6.08759 9.69054C6.02977 9.8271 6 9.97357 6 10.1215C6 10.2694 6.02977 10.4159 6.08759 10.5525C6.14542 10.689 6.23015 10.813 6.33691 10.9171L11.1661 15.6685C11.272 15.7736 11.398 15.8569 11.5368 15.9138C11.6756 15.9707 11.8245 16 11.9748 16C12.1252 16 12.274 15.9707 12.4128 15.9138C12.5516 15.8569 12.6776 15.7736 12.7835 15.6685L17.6697 10.9171C17.7752 10.8124 17.8588 10.6882 17.9154 10.5517C17.9721 10.4152 18.0008 10.269 18 10.1215C18.0008 9.97402 17.9721 9.82782 17.9154 9.69129C17.8588 9.55476 17.7752 9.43058 17.6697 9.32587C17.4563 9.11715 17.1676 9 16.8667 9C16.5658 9 16.2771 9.11715 16.0637 9.32587L11.9748 13.2928Z"
    />
  </svg>
</template>
